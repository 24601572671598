import React, { useContext, useEffect } from "react";
import classNames from 'classnames';
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "@StarberryUtils";
import { Nav, Container, Col, Row, Accordion, AccordionContext, useAccordionToggle } from "react-bootstrap";

/**
 * Assest
 */
import $ from "jquery";
import Layout from "../components/layout";
import SEO from "../components/seo";
import './styles/_sitemap.scss';


function ContextAwareToggle({ children, eventKey, callback }) {
  useEffect(() => {
      $(".open-third-level").hide();

      $(".thrid-level").each(function(){
          var thirdlevel = $(this).find("li");
          //console.log('aaaaaaaaa',thirdlevel.length)
          var ifthirdlevel = thirdlevel.length
          if(ifthirdlevel > 0) {
              $(this).parent().addClass('has-dropdown');
          }
      });
      $(".has-dropdown .open-third-level").show();

        //toggle the component with class thrid-level
    $(".open-third-level").click(function() {
      if ($('.thrid-level').is(':visible')) {
        $(".thrid-level").hide();
        $(".plusminus").text('+');
      }
      if ($(this).next(".thrid-level").is(':visible')) {
        $(this).next(".thrid-level").hide();
        $(this).children(".plusminus").text('+');
      } else {
        $(this).next(".thrid-level").stop().slideToggle('slow');

        $(this).children(".plusminus").text('-');
      }
    });
  })
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
      );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
      <button
      type="button"
      className={classNames('toggle-button', {'active': isCurrentEventKey})}
      onClick={decoratedOnClick}
      >toggle-button</button>
      );
}

const Sitemap = (props) => {

    const data = useStaticQuery(graphql`
    query SitemapQuery {
        glstrapi {
            allMenus(sort:"Sort:asc",where:{Publish:true}) {
              id
              Link_Type
              Label
              Primary_URL
              Secondary_URL
              Footer_Links
              Topmenu
              Template
              Publish
              Main_Parent {
                id
                Label
                Primary_URL
              }
              Sub_Parent {
                id
                Label
                Primary_URL
              }
              secondary_submenu {
                id
                Primary_URL
                Label
              }
    
            }
        }
    }`
    );

    //console.log('node', data.allStrapiAllMenus.edges);

    var menu_data = data.glstrapi.allMenus;

    // console.log('data_menu', menu_data);
    const thirdSubmenuUrl = (menudata, key4)=>{
      if(menudata[key4].Secondary_URL){
        return `/${menudata[key4].Secondary_URL}`;
      }else{
        return `/${menudata[key4].Main_Parent.Primary_URL}/${menudata[key4].Sub_Parent.Primary_URL}/${menudata[key4].secondary_submenu.Primary_URL}/${ menudata[key4].Primary_URL}`;
      }   
    }

    return (

        <Layout Layout={'Without_Banner'} classNames="insight_det Without_Banner">
            <SEO title={"Sitemap"} description={"Sitemap"} />

        <div className="site-map">
        <Container>
        <Row>
            <Accordion>
                <ul className="parent-menu">
                {
                    menu_data && Object.keys(menu_data).filter(key => menu_data[key].Topmenu === true).map((key, index) => {

                    var getSubMenu = menu_data && Object.keys(menu_data).filter(key2 => (menu_data[key2].Main_Parent !== null && menu_data[key2].Main_Parent.id === menu_data[key].id)).length;

                        return <>
                           <li className={menu_data[key].Label === "About" ? 'no_column' : ''}>
                               <Link to={`/${menu_data[key].Secondary_URL ? menu_data[key].Secondary_URL : menu_data[key].Primary_URL}`} >{menu_data[key].Label}</Link>

                                <ContextAwareToggle eventKey={menu_data[key].id} />
                                <Accordion.Collapse eventKey={menu_data[key].id}>
                                    
                                    <Nav className="dropdown-list">
                                        <ul className="primary_submenu">
                                        {menu_data && Object.keys(menu_data).filter(key2 => (menu_data[key2].Main_Parent !== null && menu_data[key2].Main_Parent.id === menu_data[key].id) && menu_data[key2].Sub_Parent === null).map((key2, index) =>{
                                            return(
                                                <li>
                                                    <Link to={`/${menu_data[key2].Main_Parent.Primary_URL}/${menu_data[key2].Primary_URL}`}>{menu_data[key2].Label}</Link>

                                                    <ul className="secondary_submenu">
                                                    {
                                                        menu_data && Object.keys(menu_data).filter(key3 => menu_data[key3].Main_Parent !== null && menu_data[key3].Sub_Parent !== null && menu_data[key3].secondary_submenu === null && menu_data[key3].Sub_Parent.id === menu_data[key2].id).map((key3, index) =>{
                                                            return(
                                                                <li className={menu_data && Object.keys(menu_data).filter(key4 => menu_data[key4].Main_Parent !== null && menu_data[key4].Sub_Parent !== null && menu_data[key4].secondary_submenu !== null && menu_data[key4].secondary_submenu.id === menu_data[key3].id).length > 0 ? 'third_level_items' : ''}>

                                                                  {
                                                                    menu_data[key3].Secondary_URL ? (
                                                                      menu_data[key3].Secondary_URL === "javascript:;" ? 
                                                                        <a>{menu_data[key3].Label}</a> 
                                                                      : 
                                                                        <a href={`${menu_data[key3].Secondary_URL}`}>{menu_data[key3].Label}</a>
                                                                    ) : (
                                                                      <Link to={`/${menu_data[key3].Main_Parent.Primary_URL}/${menu_data[key3].Sub_Parent.Primary_URL}/${menu_data[key3].Primary_URL}`}>{menu_data[key3].Label}</Link>
                                                                    )
                                                                  }

                                                                    {menu_data && Object.keys(menu_data).filter(key4 => menu_data[key4].Main_Parent !== null && menu_data[key4].Sub_Parent !== null && menu_data[key4].secondary_submenu !== null && menu_data[key4].secondary_submenu.id === menu_data[key3].id).length > 0 && (
                                                                        <ul className="third_submenu">
                                                                            {menu_data && Object.keys(menu_data).filter(key4 => menu_data[key4].Main_Parent !== null && menu_data[key4].Sub_Parent !== null && menu_data[key4].secondary_submenu !== null && menu_data[key4].secondary_submenu.id === menu_data[key3].id).map((key4, index) =>{
                                                                                return(
                                                                                <li>
                                                                                    <Link to={`${thirdSubmenuUrl(menu_data, key4)}`}>{menu_data[key4].Label}</Link>
                                                                                </li>
                                                                                )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    )}
                                                                    
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                    </ul>
                                                </li>
                                            )
                                        })
                                        }
                                        </ul>
                                    </Nav>
                                </Accordion.Collapse>                                
                           </li>
                        </>
                    })
                }
                </ul>

            </Accordion>
            </Row>
            
            </Container>
            </div>

        </Layout>
        

    )
}

export default Sitemap
